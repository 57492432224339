import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import {
  AngularFireFunctionsModule,
  NEW_ORIGIN_BEHAVIOR,
} from '@angular/fire/functions';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgAisModule } from 'angular-instantsearch';
import { ToastrModule } from 'ngx-toastr';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { TopBarComponent } from './layout/components/topbar/topbar.component';
import { LayoutComponent } from './layout/pages/layout.component';
import { SideBarComponent } from './layout/components/sidebar/sidebar.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';


const config = {
  apiKey: "AIzaSyCUKOefmz75NjZ4VIOHYEedREcxlId3rB8",
  authDomain: "ramsoft-1ff8a.firebaseapp.com",
  databaseURL: "https://ramsoft-1ff8a.firebaseio.com",
  projectId: "ramsoft-1ff8a",
  storageBucket: "ramsoft-1ff8a.appspot.com",
  messagingSenderId: "936544054649",
  appId: "1:936544054649:web:8c5335481229467aee13a8"
};

@NgModule({
  declarations: [
    AppComponent,
    SideBarComponent,
    TopBarComponent,
    LayoutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(config),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireMessagingModule,
    NgSelectModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    FontAwesomeModule, // ToastrModule added
    NgAisModule.forRoot(),
    KeyboardShortcutsModule.forRoot(),
    HttpClientModule,
    NgbModalModule,
  ],
  providers: [
    { provide: NEW_ORIGIN_BEHAVIOR, useValue: true },
    // {
    //   provide: ORIGIN,
    //   useValue: 'https://asia-south1-nodalaari.cloudfunctions.net/',
    // },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
