import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layou',
  template: `<app-topbar></app-topbar>
  <router-outlet></router-outlet>`,

})
export class LayoutComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }
}
